<template>
	<div>
		<div v-if="requiresLabel">
			<p class="results-for-text">{{ $t('label.resultsFor') }}</p>
			<div class="date-range-and-total-amount">
				<span class="statement-search-date-range mr-2">{{ dateRangeString }}</span>
				<span class="statement-search-paid-amount mr-2"
					>{{ $t('label.bluerCrossPaid') }} {{ totalAmountFormatted }}</span
				>
			</div>
		</div>
		<BaseTable
			id="paymentHistoryTable"
			:items="prepareData"
			stacked="md"
			:fields="getColumns()"
			:current-page="currentPage"
			:per-page="perPage"
			small
			:class="['base-table-styles', 'mt-2']"
		>
			<template #head(dateProcessed)="data">
				<span class="text-info">{{ data.label }}</span>
				<InfoModal
					class="date-processed-button-header"
					automation-id="date-processed-popup"
					:modal-title="$t('modal.title')"
				>
					{{ $t('modal.text') }}
				</InfoModal>
			</template>
			<template #cell(dateProcessed)="row">
				<span>{{ row.value }}</span>
				<InfoModal
					class="date-processed-button-body"
					automation-id="date-processed-popup"
					:modal-title="$t('modal.title')"
				>
					{{ $t('modal.text') }}
				</InfoModal>
			</template>

			<template #cell(details)="row">
				<BaseButton
					:id="row.index"
					variant="link"
					:pill="false"
					type="button"
					class="statement-details-button"
					:data-tracking-id="`statementResult${row.index}`"
					:automation-id="getAutomationId(`statementResult${row.index}`)"
					:label="$t('label.view')"
					@click="getDetails(row.item)"
				/>
			</template>
		</BaseTable>
		<BasePagination
			v-if="totalRows > 50 && requiresPagination"
			:current-page="currentPage"
			:total-rows="totalRows"
			:per-page="perPage"
			@input="pageChange"
		/>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BTr, BTd } from 'bootstrap-vue';
import BaseTable from '@/components/common/base/BaseTable';
import BasePagination from '@/components/common/base/BasePagination';
import BaseButton from '@/components/common/base/BaseButton';
import InfoModal from '@/components/common/InfoModal';

@Component({
	name: 'StatementSearchResults',
	components: {
		BaseTable,
		BasePagination,
		BTr,
		BTd,
		BaseButton,
		InfoModal
	},
	props: {
		statementSearchResults: {
			type: Array,
			default: () => []
		},
		dateRangeString: {
			type: String,
			default: null
		},
		perPage: {
			type: Number,
			default: 50
		},
		totalAmount: {
			type: String,
			default: ''
		},
		requiresLabel: {
			type: Boolean,
			default: true
		},
		requiresPagination: {
			type: Boolean,
			default: true
		},
		showHSP: {
			type: Boolean,
			default: false
		}
	},
	mixins: [IdMixin]
})
export default class StatementSearchResults extends Vue {
	currentPage = 1;
	totalRows = this.statementSearchResults ? this.statementSearchResults.length : 0;
	isModalVisible = false;

	mounted() {
		this.currentPage = 1;
		this.ariaRowcount();
	}

	get prepareData() {
		this.currentPage = 1;
		this.totalRows = this.statementSearchResults ? this.statementSearchResults.length : 0;
		var self = this;
		let index = 0;
		var preparedData = [];
		this.statementSearchResults.forEach(function (statementResult) {
			var newDate =
				statementResult.dateProcessed.slice(0, 4) +
				'/' +
				statementResult.dateProcessed.slice(4, 6) +
				'/' +
				statementResult.dateProcessed.slice(6);
			const dateProcessed = new Date(newDate);
			var options = { day: '2-digit', month: 'short', year: 'numeric' };
			if (self.isEnglish) {
				preparedData.push({
					id: index,
					dateProcessed: dateProcessed.toLocaleDateString('en-GB', options),
					blueCrossPaid: Number(statementResult.paidAmount).toLocaleString('en-CA', {
						style: 'currency',
						currency: 'CAD'
					}),
					paymentType: statementResult.paymentType,
					spendingAccountUsed: statementResult.spendingAccountUsed
						? self.$t('label.yes')
						: self.$t('label.no'),
					paymentId: statementResult.paymentId
				});
			} else {
				preparedData.push({
					id: index,
					dateProcessed: dateProcessed.toLocaleDateString('fr-FR', options),
					blueCrossPaid: Number(statementResult.paidAmount).toLocaleString('fr-CA', {
						style: 'currency',
						currency: 'CAD'
					}),
					paymentType: statementResult.paymentType,
					spendingAccountUsed: statementResult.spendingAccountUsed
						? self.$t('label.yes')
						: self.$t('label.no'),
					paymentId: statementResult.paymentId
				});
			}
			index++;
		});
		return preparedData;
	}

	//This method deletes aria-rowcount attr from the table for screen readers to read only number of rows that are displayed
	ariaRowcount() {
		const table = document.getElementById('paymentHistoryTable');
		table.removeAttribute('aria-rowcount');
	}

	getDetails(item) {
		this.$emit('details', item);
	}

	pageChange(page) {
		this.currentPage = page;
	}

	/**
	 * Returns the list of columns
	 * @returns {array}
	 */
	getColumns() {
		return [
			{
				key: 'dateProcessed',
				label: this.$t('column.dateProcessed'),
				thClass: 'th-date-class',
				tdClass: 'td-date-class'
			},
			{
				key: 'blueCrossPaid',
				label: this.$t('column.blueCrossPaid'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'paymentType',
				label: this.$t('column.paymentType'),
				thClass: 'th-type-class',
				tdClass: 'td-type-class'
			},
			this.showHSP
				? {
						key: 'spendingAccountUsed',
						label: this.$t('column.spendingAccountUsed'),
						thClass: this.spendingAccountUsedTHClass,
						tdClass: this.spendingAccountUsedTDClass
				  }
				: null,
			{
				key: 'details',
				label: this.$t('column.details'),
				thClass: 'th-details-class',
				tdClass: 'td-details-class'
			}
		];
	}

	// Gets the locale to set a watch to see if member changed language.
	get locale() {
		return this.$store.state.i18n.locale;
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	get spendingAccountUsedTHClass() {
		if (this.statementSearchResults && this.showHSP) {
			return 'th-spending-account-class';
		}
		return 'd-none';
	}

	get spendingAccountUsedTDClass() {
		if (this.statementSearchResults && this.showHSP) {
			return 'td-spending-account-class';
		}
		return 'd-none';
	}

	get totalAmountFormatted() {
		if (this.isEnglish) {
			return Number(this.totalAmount).toLocaleString('en-CA', {
				style: 'currency',
				currency: 'CAD'
			});
		} else {
			return Number(this.totalAmount).toLocaleString('fr-CA', {
				style: 'currency',
				currency: 'CAD'
			});
		}
	}

	showInfoPopup() {
		this.isModalVisible = true;
	}

	changeModalVisibility(visibility) {
		this.isModalVisible = visibility;
	}
}
</script>

<style lang="scss" scoped>
.download-button {
	min-width: 120px;
	background-color: $blue;
	border-radius: 30px;
	font-family: $josefin-font-family;
	font-size: $body-small-font-size;
	font-weight: $font-regular;
	margin-left: 1em;
}

.base-table-styles ::v-deep tr:nth-child(even) {
	background-color: $gray-very-light;
}

.base-table-styles {
	background-color: #ffffff;
}

.base-table-styles ::v-deep th {
	font-size: 15px;
	font-family: $font-family-headline;
	font-weight: 300;
}
.base-table-styles ::v-deep td {
	font-size: 15px;
	font-family: $font-family-regular;
	font-weight: 300;
}

.base-table-styles ::v-deep tbody tr [data-label]::before {
	font-size: 15px;
	font-family: $font-family-headline;
	font-weight: 300;
	position: relative;
	top: 3px;
}

// Amount column
.base-table-styles ::v-deep .th-amount-class {
	text-align: right;
}
.base-table-styles ::v-deep .td-amount-class {
	text-align: right;
	@media (max-width: 767px) {
		text-align: left;
	}
}
// Details column
.base-table-styles ::v-deep .td-details-class {
	text-align: left;
}
.statement-details-button {
	padding: 0px;
}
.statement-details-button ::v-deep .button-label {
	margin: 0px;
	text-align: left;
}

.statement-info-button {
	padding: 0px;
}
.date-processed-top-paragraph {
	font-family: $font-family-headline;
	font-size: 21px;
	margin-bottom: 10px;
}
.date-processed-bottom-paragraph {
	font-family: $font-family-regular;
	font-weight: 300;
	font-size: 17px;
}
.date-range-and-total-amount {
	display: block;
	overflow: auto;
	margin-bottom: 20px;
}
.results-for-text {
	margin: 0px;
	font-family: $font-family-headline;
	font-size: 21px;
}
.statement-search-date-range {
	float: left;
	@media (max-width: 550px) {
		float: none;
		display: block;
	}
}
.statement-search-paid-amount {
	font-weight: 500;
	float: right;
	@media (max-width: 550px) {
		float: none;
		display: block;
		margin-top: 12px;
	}
}
.date-processed-button-header {
	padding: 0px;
	display: inline;
	@media (max-width: 768px) {
		display: none;
	}
}
.date-processed-button-body {
	padding: 0px;
	display: inline;
	@media (min-width: 768px) {
		display: none;
	}
}
</style>

<i18n>
{
  "en": {
	"column": {
		"dateProcessed": "Date Processed",
		"blueCrossPaid": "Blue Cross Paid",
		"paymentType": "Payment Type",
		"spendingAccountUsed": "Spending Account Used",
		"details": "Details"
	},
	"label": {
		"yes": "Yes",
		"no": "No",
		"resultsFor": "Results for:",
		"bluerCrossPaid": "Blue Cross Paid:",
		"view": "View >"
	},
	"modal": {
		"title": "What does this mean?",
		"text": "'Date processed' refers to the date that we processed your submitted claim and issued your payment. If you've chosen to be reimbursed through direct deposit, it can take up to 7 business days for your bank to deposit your money."
	}
  },
  "fr": {
	"column": {
		"dateProcessed": "Date de traitement",
		"blueCrossPaid": "Croix Bleue a payé",
		"paymentType": "Type de paiement",
		"spendingAccountUsed": "Compte Gestion-santé utilisé",
		"details": "Détails"
	},
	"label": {
		"yes": "Oui",
		"no": "Non",
		"resultsFor": "Résultats pour :",
		"bluerCrossPaid": "Croix Bleue a payé :",
		"view": "Consulter >"
	},
	"modal": {
		"title": "Qu’est-ce que cela signifie?",
		"text": "La « date de traitement » est la date à laquelle nous avons traité votre demande de règlement et émis votre paiement. Si vous avez opté pour le remboursement par dépôt direct, il faut compter jusqu’à 7 jours ouvrables pour que votre banque dépose votre argent."
	}
  }
}
</i18n>
